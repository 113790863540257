import { ProductAPI } from "@/api";
import Page from "@/components/page";
import ProductForm from "@/components/product-form/form";
import { draftSchema, publishSchema, TProductForm } from "@/components/product-form/schema";
import { buttonVariants } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { TBrand } from "@/types/brand";
import { TCategory } from "@/types/category";
import { TColor } from "@/types/color";
import { TModel } from "@/types/model";
import { TProductDetailed } from "@/types/product";
import { TTag } from "@/types/tag";
import _ from "lodash";
import { ExternalLink } from "lucide-react";
import { Trans, useTranslation } from "react-i18next";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { TInspectionListData } from "@/types/used-product.type.ts";

export default function ProductEdit() {
  const params = useParams() as { id: string };
  const data = useLoaderData() as null | {
    product: TProductDetailed;
    brands: TBrand[];
    models: TModel[];
    colors: TColor[];
    categories: TCategory[];
    subcategories: TCategory[];
    tags: TTag[];
  };
  const { t } = useTranslation();
  const navigate = useNavigate();

  // TODO: implement Error Page
  if (!data) return <div>Error</div>;

  const onValidate = (data: TProductForm) => {
    const vld = publishSchema.safeParse(data);
    if (!vld.success) {
      const fields = Object.keys(vld.error.flatten().fieldErrors);
      return fields.map((key) => t(`products.errors.${key}`));
    }
    return null;
  };

  const onPublish = (data: TProductForm) => {
    const vld = publishSchema.safeParse(data);
    if (!vld.success) {
      const fields = Object.keys(vld.error.flatten().fieldErrors);
      return fields.map((key) => t(`products.errors.${key}`));
    }

    return async function () {
      const productColor = JSON.parse(
        JSON.stringify({
          has_color: !!data.color?.base_color?.length,
          base_color: data.color?.base_color?.length ? data.color?.base_color : undefined,
          product_color_name_en: data.color?.product_color_name_en?.trim().length
            ? data.color?.product_color_name_en
            : undefined,
          product_color_name_fa: data.color?.product_color_name_fa?.trim().length
            ? data.color?.product_color_name_fa
            : undefined,
        }),
      );

      const productDesc = JSON.parse(
        JSON.stringify({
          fa: data.description?.fa.length ? data.description.fa : undefined,
          en: data.description?.en.length ? data.description?.en : undefined,
        }),
      );
      try {
        const isUsed = data.price?.is_used ?? false;
        await ProductAPI.update(params.id, {
          admin_preferred_related_products: [],
          availability: {
            is_available: true, //TODO: check this later
            total_count: data.availability?.total_count ? Number(data.availability?.total_count) : 0,
          },
          brand: data.brand,
          category: data.category,
          color: _.isEmpty(productColor) ? productColor : undefined,
          description: _.isEmpty(productDesc) ? undefined : productDesc,
          name: data.name.trim(),
          price: {
            call_to_buy: data.price?.call_to_buy ?? false,
            is_used: isUsed,
            sale: {
              is_on_sale: data.price?.sale?.is_on_sale ?? false,
              off_percent: Number(data.price.sale?.off_percent) ?? 0,
            },
            price: Number(data.price?.price) ?? 0,
            is_competitive: false,
          },
          features: data.features ?? [],
          images: data.images ?? [],
          model: data.model,
          seo_name_en: data.seo_name_en.trim(),
          seo_name_fa: data.seo_name_fa.trim(),
          subcategory: data.subcategory,
          properties:
            data.properties?.map((prop) => ({
              ...prop,
              title: { fa: prop.title.fa.trim(), en: prop.title.en.trim() },
              items: prop.items ?? [],
            })) ?? [],
          tags: data.tags ?? [],
          videos: [],
        });
        const { message } = await ProductAPI.publish(params.id);
        toast({
          description: message.fa,
        });
        if (isUsed) {
          await ProductAPI.setInspection({
            product_id: params.id,
            product_name: data.name,
            brand_id: data.brand,
            items: data.healthTable as TInspectionListData,
          });
        }
        navigate(-1);
      } catch (err) {
        toast({ description: (err as Error).message, variant: "destructive" });
      }
    };
  };

  const onDraft = (data: TProductForm) => {
    const vld = draftSchema.safeParse(data);
    if (!vld.success) {
      const fields = Object.keys(vld.error.flatten().fieldErrors);
      return fields.map((key) => t(`products.errors.${key}`));
    }

    return async function () {
      const productColor = JSON.parse(
        JSON.stringify({
          has_color: !!data.color?.base_color?.length,
          base_color: data.color?.base_color?.length ? data.color?.base_color : undefined,
          product_color_name_en: data.color?.product_color_name_en?.trim().length
            ? data.color?.product_color_name_en
            : undefined,
          product_color_name_fa: data.color?.product_color_name_fa?.trim().length
            ? data.color?.product_color_name_fa
            : undefined,
        }),
      );

      const productDesc = JSON.parse(
        JSON.stringify({
          fa: data.description?.fa.length ? data.description.fa : undefined,
          en: data.description?.en.length ? data.description?.en : undefined,
        }),
      );
      try {
        const isUsed = data.price?.is_used ?? false;
        await ProductAPI.update(params.id, {
          admin_preferred_related_products: [],
          availability: {
            is_available: true, //TODO: check this later
            total_count: data.availability?.total_count ? Number(data.availability?.total_count) : 0,
          },
          brand: data.brand,
          category: data.category,
          color: _.isEmpty(productColor) ? productColor : undefined,
          description: _.isEmpty(productDesc) ? undefined : productDesc,
          name: data.name.trim(),
          price: {
            call_to_buy: data.price?.call_to_buy ?? false,
            is_used: isUsed,
            sale: {
              is_on_sale: data.price?.sale?.is_on_sale ?? false,
              off_percent: Number(data.price.sale?.off_percent) ?? 0,
            },
            price: Number(data.price?.price) ?? 0,
            is_competitive: false,
          },
          features: data.features ?? [],
          images: data.images ?? [],
          model: data.model,
          seo_name_en: data.seo_name_en.trim(),
          seo_name_fa: data.seo_name_fa.trim(),
          subcategory: data.subcategory,
          properties: data.properties?.map((prop) => ({ ...prop, items: prop.items ?? [] })) ?? [],
          tags: data.tags ?? [],
          videos: [],
        });
        const resp = await ProductAPI.draft(params.id);
        if (isUsed) {
          await ProductAPI.setInspection({
            product_id: params.id,
            product_name: data.name,
            brand_id: data.brand,
            items: data.healthTable as TInspectionListData,
          });
        }
        navigate(-1);
        toast({
          description: resp.data.data.message.fa,
        });
      } catch (err) {
        toast({ description: (err as Error).message, variant: "destructive" });
      }
    };
  };

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("productsManagement.title"), link: "/products" },
        { label: t("products.products"), link: "/products/list" },
        { label: t("products.editLabel") },
      ]}
      title={t("products.edit", { title: data.product.name })}
      action={
        <div className="flex items-center gap-2">
          <div className="flex pointer-events-none">
            <div className={buttonVariants({ variant: data.product.is_draft ? "default" : "tertiary" })}>
              <Trans i18nKey="products.isDraft" />
            </div>
            <div className={buttonVariants({ variant: data.product.is_published ? "default" : "tertiary" })}>
              <Trans i18nKey="products.isPublished" />
            </div>
          </div>
          <a
            href={import.meta.env.VITE_SHOP_BASE_URL + "/product/" + data.product.slug}
            target="_blank"
            className={buttonVariants({
              variant: "secondary",
              className: "cursor-pointer flex items-center gap-1",
            })}
          >
            <ExternalLink size={16} className="inline-block mb-1" />
            <Trans i18nKey="products.showProductPage" />
          </a>
        </div>
      }
      classes={{ container: "flex-1 flex flex-col" }}
    >
      <ProductForm {...data} onDraft={onDraft} onPublish={onPublish} onValidate={onValidate} />
    </Page>
  );
}
