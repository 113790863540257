export default {
  edit: "ویرایش محصول {{title}}",
  name: "نام محصول",
  products: "محصولات",
  editLabel: "ویرایش محصول",
  editFeature: "ویرایش امکانات",
  brand: "برند",
  model: "مدل",
  color: "رنگ",
  farsi: "فارسی",
  english: "انگلیسی",
  category: "دسته‌بندی",
  subcategory: "زیردسته‌بندی",
  tags: "تگ‌ها",
  seoNameFa: "نام سئو فارسی",
  seoNameEn: "نام سئو انگلیسی",
  price: "قیمت محصول (تومان)",
  count: "تعداد محصول",
  isUsed: "محصول دست دوم می باشد",
  changeToUsed: "تغییر به دست دوم",
  inspectionFile: "فایل سلامت سنجی",
  used: "دست دوم",
  isOnSale: "محصول در حراج است",
  callToBuy: "کاربران برای اطلاع از قیمت ها باید تماس بگیرند",
  uploadPreview: "پیش‌نمایش",
  uploadThumbnail: "بندانگشتی",
  features: "امکانات محصول",
  addNewFeature: "امکانات جدید",
  description: "توضیحات محصول",
  properties: "مشخصات محصول",
  addNewGroup: "گروه جدید",
  addNewSpec: "آیتم جدید",
  editSpec: "ویرایش آیتم",
  icon: "آیکون",
  nameFa: "نام فارسی",
  nameEn: "نام انگلیسی",
  descFa: "توضیحات فارسی",
  descEn: "توضیحات انگلیسی",
  duplicate: "یک محصول دیگر با این مشخصات بساز",
  duplicated: "محصول جدید ساخته شد",
  validate: "بررسی و اعتبارسنجی",
  primaryImage: "تصویر نمایشی",
  draft: "ذخیره پیشنویس",
  publish: "انتشار",
  selectColor: "رنگ",
  selectBrand: "انتخاب برند",
  selectModel: "انتخاب مدل",
  informationSection: "مشخصات کلی",
  priceSection: "موجودی محصول",
  gallerySection: "تصاویر محصول",
  propertiesSection: "ویژگی های محصول",
  healthSection: "سلامت محصول",
  productTitle: "محصول",
  productPrice: "قیمت",
  createdAt: "ایجاد در",
  updatedAt: "بروزرسانی در",
  colors: "رنگ‌ها",
  addNewProduct: "افزودن محصول جدید",
  totalProducts: "تعداد کل محصولات",
  isDraft: "پیشنویس",
  isPublished: "منتشرشده",
  viewProducts: "مشاهده محصولات",
  showProductPage: "نمایش محصول در شاپ",
  showDetails: "جزییات",
  shop: "شاپ",
  offPercent: "درصد تخفیف",
  productsList: "لیست محصولات",
  delete: "حذف محصول",
  errors: {
    hasError: "خطاها را بررسی کنید",
    noError: "بدون خطا",
    name: "نام محصول را بررسی کنید",
    brand: "برند محصول را بررسی کنید",
    model: "مدل محصول را بررسی کنید",
    category: "دسته‌بندی محصول را بررسی کنید",
    subcategory: "زیردسته‌بندی محصول را بررسی کنید",
    seo_name_fa: "نام سئو فارسی محصول را بررسی کنید",
    seo_name_en: "نام سئو انگلیسی محصول را بررسی کنید",
    tags: "تگ‌های محصول را بررسی کنید",
    description: "توضیحات محصول را بررسی کنید",
    color: "رنگ محصول را بررسی کنید",
    price: "قیمت محصول را بررسی کنید",
    images: "تصاویر محصول را بررسی کنید",
    availability: "موجودی محصول را بررسی کنید",
    features: "امکانات محصول را بررسی کنید",
    properties: "مشخصات محصول را بررسی کنید",
    health: "در بخش سلامت محصول، امتیاز موارد فعال را وارد کنید",
  },
  deleteSure: "آیا از حذف این محصول اطمینان دارید؟",
  deleteDesc:
    "درصورت حذف محصول ممکن است مشکلاتی در سیستم رخ دهد. حتما قبل از حذف، هماهنگی‌های لازم را با مسئول مربوطه انجام دهید.",
  priceText: {
    callToBuy: "تماس بگیرید",
    unavailable: "ناموجود",
  },
  healthCheck: {
    status: "وضعیت",
    properties: "ویژگی",
    actualValue: "امتیاز محصول",
    value: "مقدار",
    note: "یادداشت",
  },
  watermark: "تصاویر واترمارک داشته باشند",
};
