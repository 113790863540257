import { createBrowserRouter, Navigate } from "react-router-dom";
import MobileView from "@/pages/mobile";
import RootLayout from "@/components/layout/root-layout";
import ProtectedRoute from "@/components/protected-route";
import Login from "@/pages/login";
import Orders from "@/pages/orders-list";
import ordersLoader from "@/pages/orders-list/loader";
import OrderDetail from "@/pages/orders-detail";
import orderDetailLoader from "@/pages/orders-detail/loader";
import ProductsManagementPage from "@/pages/products-management";
import productsManagementLoader from "@/pages/products-management/loader";
import ProductsCategories from "@/pages/products-categories";
import categoryListLoader from "@/pages/products-categories/loader";
import CategoryAdd from "@/pages/category-add";
import CategoryEdit from "@/pages/category-edit";
import categoryLoader from "@/pages/category-edit/loader";
import ProductsSubcategories from "@/pages/products-subcategories";
import SubcategoryAdd from "@/pages/subcategory-add";
import SubcategoryEdit from "@/pages/subcategory-edit";
import subcategoryLoader from "@/pages/subcategory-edit/loader";
import SubcategoriesList from "@/pages/subcategories-list";
import subcategoriesListLoader from "@/pages/subcategories-list/loader";
import ProductsTags from "@/pages/products-tags";
import productsTagsListLoader from "@/pages/products-tags/loader";
import TagAdd from "@/pages/tag-add";
import newTagLoader from "@/pages/tag-add/loader";
import TagEdit from "@/pages/tag-edit";
import tagLoader from "@/pages/tag-edit/loader";
import ProductsBrands from "@/pages/products-brands";
import productBrandsLoader from "@/pages/products-brands/loader";
import BrandAdd from "@/pages/brand-add/page";
import BrandEdit from "@/pages/brand-edit/page";
import brandEditLoader from "@/pages/brand-edit/loader";
import PricesList from "@/pages/products-prices";
import pricesLoader from "@/pages/products-prices/loader";
import ProductEdit from "@/pages/product-edit";
import productLoader from "@/pages/product-edit/loader";
import ProductsList from "@/pages/products-list";
import productsListLoader from "@/pages/products-list/loader";
import ProductAdd from "@/pages/product-add";
import productsAddLoader from "@/pages/product-add/loader";
import ProductsModels from "@/pages/products-models";
import productsModelsListLoader from "@/pages/products-models/loader";
import UsersList from "@/pages/users-list";
import usersListLoader from "@/pages/users-list/loader";
import UserEdit from "@/pages/user-edit";
import userEditLoader from "@/pages/user-edit/loader";
import WebsiteManagement from "@/pages/website-management";
import AboutUs from "@/pages/about-us";
import aboutUsLoader from "@/pages/about-us/loader";
import Policy from "@/pages/policy";
import policyLoader from "@/pages/policy/loader";
import Carousel from "@/pages/carousel";
import carouselLoader from "@/pages/carousel/loader";
import FAQ from "@/pages/faq";
import faqLoader from "@/pages/faq/loader";
import AdminsList from "@/pages/admins-management";
import adminsListLoader from "@/pages/admins-management/loader";
import AdminEdit from "@/pages/admin-edit";
import adminEditLoader from "@/pages/admin-edit/loader";
import SlidersManagement from "@/pages/slider-management";
import sliderListLoader from "@/pages/slider-management/loader";
import SliderEdit from "@/pages/slider-edit";
import sliderEditLoader from "@/pages/slider-edit/loader";
import ProtectedOutlet from "@/components/protected-outlet";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import Forbidden from "@/pages/forbidden";
import Comments from "@/pages/comments";
import commentsListLoader from "@/pages/comments/loader";
import LogsList from "@/pages/logs-list";
import logsListLoader from "@/pages/logs-list/loader";
import LogShow from "@/pages/log-show";
import logShowLoader from "@/pages/log-show/loader";
import UsedProducts from "@/pages/used-products";
import usedProductsLoader from "@/pages/used-products/loader";
import { repairRouteObjects } from "@/router/repair.route.tsx";
import HealthInspectionPage from "@/pages/health-inspection";
import loadHealthInspectionData from "@/pages/health-inspection/loader.ts";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <RootLayout />
      </ProtectedRoute>
    ),
    children: [
      // Forbidden
      { path: "forbidden", element: <Forbidden /> },
      // Dashboard
      {
        index: true,
        element: <Navigate to="/orders" replace />,
      },
      // Orders
      {
        path: "orders",
        children: [
          {
            element: <ProtectedOutlet allowedPermissions={[ADMIN_PERMISSIONS.READ_ORDER]} />,
            children: [
              { index: true, loader: ordersLoader, element: <Orders /> },
              { path: ":id", loader: orderDetailLoader, element: <OrderDetail /> },
            ],
          },
        ],
      },
      // Users
      {
        path: "users",
        children: [
          {
            element: <ProtectedOutlet allowedPermissions={[ADMIN_PERMISSIONS.READ_USER]} />,
            children: [
              { index: true, loader: usersListLoader, element: <UsersList /> },
              { path: ":id", loader: userEditLoader, element: <UserEdit /> },
            ],
          },
        ],
      },
      // Admins
      {
        path: "admins",
        children: [
          {
            element: <ProtectedOutlet allowedPermissions={[ADMIN_PERMISSIONS.READ_ADMIN]} />,
            children: [
              { index: true, loader: adminsListLoader, element: <AdminsList /> },
              { path: ":id", loader: adminEditLoader, element: <AdminEdit /> },
            ],
          },
        ],
      },
      // Products
      {
        path: "products",
        children: [
          // Products list
          {
            element: (
              <ProtectedOutlet
                allowedPermissions={[
                  ADMIN_PERMISSIONS.READ_PRODUCT,
                  ADMIN_PERMISSIONS.READ_CATEGORY,
                  ADMIN_PERMISSIONS.READ_SUB_CATEGORY,
                  ADMIN_PERMISSIONS.READ_TAG,
                  ADMIN_PERMISSIONS.READ_BRAND,
                  ADMIN_PERMISSIONS.READ_MODEL,
                ]}
              />
            ),
            children: [
              { index: true, loader: productsManagementLoader, element: <ProductsManagementPage /> },
              { path: "list", loader: productsListLoader, element: <ProductsList /> },
              { path: "health-inspection", loader: loadHealthInspectionData, element: <HealthInspectionPage /> },
              { path: ":id", loader: productLoader, element: <ProductEdit /> },
            ],
          },
          {
            element: <ProtectedOutlet allowedPermissions={[ADMIN_PERMISSIONS.WRITE_PRODUCT]} />,
            children: [{ path: "add/:productId?", loader: productsAddLoader, element: <ProductAdd /> }],
          },
          // Categories
          {
            path: "categories",
            children: [
              {
                element: <ProtectedOutlet allowedPermissions={[ADMIN_PERMISSIONS.READ_CATEGORY]} />,
                children: [
                  { index: true, element: <ProductsCategories />, loader: categoryListLoader },
                  { path: ":categoryId", element: <CategoryEdit />, loader: categoryLoader },
                ],
              },
              {
                element: <ProtectedOutlet allowedPermissions={[ADMIN_PERMISSIONS.WRITE_CATEGORY]} />,
                children: [{ path: "add", element: <CategoryAdd /> }],
              },
              // Subcategories
              {
                path: "subcategories",
                element: <ProtectedOutlet allowedPermissions={[ADMIN_PERMISSIONS.READ_SUB_CATEGORY]} />,
                children: [
                  {
                    path: "",
                    element: <ProductsSubcategories />,
                    loader: categoryListLoader,
                    children: [
                      {
                        path: ":categoryId",
                        loader: subcategoriesListLoader,
                        element: <SubcategoriesList />,
                      },
                    ],
                  },
                ],
              },
              {
                element: <ProtectedOutlet allowedPermissions={[ADMIN_PERMISSIONS.READ_SUB_CATEGORY]} />,
                children: [
                  {
                    path: "subcategories/:categoryId/edit/:subcategoryId",
                    loader: subcategoryLoader,
                    element: <SubcategoryEdit />,
                  },
                ],
              },
              {
                element: <ProtectedOutlet allowedPermissions={[ADMIN_PERMISSIONS.WRITE_SUB_CATEGORY]} />,
                children: [{ path: "subcategories/:categoryId/add", element: <SubcategoryAdd /> }],
              },
            ],
          },
          // Tags
          {
            path: "tags",
            children: [
              {
                element: <ProtectedOutlet allowedPermissions={[ADMIN_PERMISSIONS.READ_TAG]} />,
                children: [
                  { index: true, element: <ProductsTags />, loader: productsTagsListLoader },
                  { path: ":id", element: <TagEdit />, loader: tagLoader },
                ],
              },
              {
                element: <ProtectedOutlet allowedPermissions={[ADMIN_PERMISSIONS.WRITE_TAG]} />,
                children: [{ path: "add", element: <TagAdd />, loader: newTagLoader }],
              },
            ],
          },
          // Brands
          {
            path: "brands",
            children: [
              {
                element: <ProtectedOutlet allowedPermissions={[ADMIN_PERMISSIONS.READ_BRAND]} />,
                children: [
                  { index: true, element: <ProductsBrands />, loader: productBrandsLoader },
                  { path: ":id", element: <BrandEdit />, loader: brandEditLoader },
                ],
              },
              {
                element: <ProtectedOutlet allowedPermissions={[ADMIN_PERMISSIONS.WRITE_BRAND]} />,
                children: [{ path: "add", element: <BrandAdd /> }],
              },
            ],
          },
          // Prices
          {
            path: "prices",
            loader: pricesLoader,
            element: <PricesList />,
          },
          // Models
          {
            path: "models",
            element: <ProtectedOutlet allowedPermissions={[ADMIN_PERMISSIONS.READ_MODEL]} />,
            children: [{ index: true, element: <ProductsModels />, loader: productsModelsListLoader }],
          },
        ],
      },
      // Website
      {
        path: "website",
        element: <ProtectedOutlet allowedPermissions={[ADMIN_PERMISSIONS.READ_STATICS]} />,
        children: [
          { index: true, element: <WebsiteManagement /> },
          { path: "about-us", loader: aboutUsLoader, element: <AboutUs /> },
          { path: "policy", loader: policyLoader, element: <Policy /> },
          { path: "carousel", loader: carouselLoader, element: <Carousel /> },
          { path: "faq", loader: faqLoader, element: <FAQ /> },
          {
            path: "slider",
            loader: sliderListLoader,
            children: [
              { index: true, loader: sliderListLoader, element: <SlidersManagement /> },
              { path: ":name", loader: sliderEditLoader, element: <SliderEdit /> },
            ],
          },
          { path: "used-products", loader: usedProductsLoader, element: <UsedProducts /> },
        ],
      },
      // Comments
      {
        path: "comments",
        element: <ProtectedOutlet allowedPermissions={[ADMIN_PERMISSIONS.READ_COMMENT]} />,
        children: [{ index: true, loader: commentsListLoader, element: <Comments /> }],
      },
      ...repairRouteObjects,
      {
        path: "logs",
        element: <ProtectedOutlet allowedPermissions={[ADMIN_PERMISSIONS.READ_LOG]} />,
        children: [
          { index: true, loader: logsListLoader, element: <LogsList /> },
          { path: ":id", loader: logShowLoader, element: <LogShow /> },
        ],
      },
    ],
  },
  { path: "/auth", children: [{ path: "login", element: <Login /> }] },
  { path: "/mobile", element: <MobileView /> },
]);

export default router;
